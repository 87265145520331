<script setup></script>

<template>
    <div class="plan_details dashboard_root">
        <div v-if="$store.state.active_org" class="plan_wrap flex">
            <div class="flex plan_info flex_col">
                <p>Activate Rate: {{ $store.state.rate }}</p>
                <p>Usage: {{ $store.state.usage }}</p>
                <p>Days Left in Cycle: {{ $store.state.days_left_in_cycle }}</p>
                <p>Accrued Charges: ${{ $store.state.metered_cost }}</p>
                <p>Credits: {{ $store.state.credits }}</p>
            </div>
            <div class="plan_info flex_col flex user_actions" v-if="$store.state.active_org">
                <router-link class="flex" :to="{ path: '/buy-credits', query: { org: `${$store.state.active_org.id}` } }">
                    <img src="@/assets/buy_requests.svg" alt="" />&nbsp;&nbsp;&nbsp;
                    <p>Buy Request Credits</p>
                </router-link>
                <router-link class="flex" :to="{ name: 'Paymethod', query: { org: `${$store.state.active_org.id}` } }">
                    <img src="@/assets/credit-card.svg" alt="" />&nbsp;&nbsp;&nbsp;
                    <p v-if="$store.state.payment === 'Not on file'">Add Credit Card</p>
                    <p v-else>Update Credit Card on File</p>
                </router-link>
                <router-link :to="{ name: 'Update plan' }" class="flex">
                    <img src="@/assets/id.svg" alt="" />&nbsp;&nbsp;&nbsp;
                    <p>Update Plan</p>
                </router-link>
                <router-link :to="{ path: '/invoices', query: { org: `${$store.state.active_org.id}` } }" class="flex">
                    <img width="25" src="@/assets/invoice_svg.svg" alt="" />&nbsp;&nbsp;&nbsp;
                    <p>Invoices</p>
                </router-link>
                <a href="mailto:support@rivet.cloud" target="_blank" class="flex">
                    <img width="25" src="@/assets/support.svg" alt="" />&nbsp;&nbsp;&nbsp;
                    <p>Contact Support</p>
                </a>
            </div>
        </div>
        <div v-else class="ajax_loader ta_center">
            <br /><br />
            <img width="40" src="https://res.cloudinary.com/ferventdev/image/upload/v1655149185/rivet.cloud/1488_t1b6v8.gif" alt="" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.plan_details {
    .plan_wrap {
        align-items: flex-start;
        justify-content: space-evenly;
        @include media("<=phone-tab") {
            @include flex_col(3rem 0);
        }
        .plan_info {
            gap: 2rem 0;
            width: 43%;
            img {
                @extend %nodrag;
                @extend %noselect;
            }
            @include media("<=phone-tab") {
                width: 100%;
            }
            a {
                text-decoration: none;
                color: inherit;
                @extend %nodrag;
                &:hover {
                    color: #7ebbe9;
                }
            }
        }
    }
}
</style>
